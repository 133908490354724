.quotelistviewlist {
	
}

.quotelistviewlist__product {
	margin: 35px 0 0;
}
.quotelistviewlist__product:nth-of-type(1) {
	margin-top: 0;
}

.pagebreak { 
	page-break-after: always;
}

.quotelistviewlist:after,
.quotelistviewlist__product:after {
	content: "";
	display: block;
	clear: both;
}

.quotelistviewlist__box {
    background-color: white;
    border-radius: 4px 4px 0 4px;
    border-top: 4px solid #217AD9;
    padding: 20px;
}

.quotelistviewlist__box--empty {
	border: none;
}

.quotelistviewlist__configuration .heading--6 {
    margin-bottom: 10px;
}

.quotelistviewlist__configuration {
	position: relative;
    border-bottom: 1px solid #F0F3F6;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.quotelistviewlist__configuration:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
}

.quotelistviewlist__configuration-price {
	position: absolute;
	top: 0;
	right: 0;
}

.quotelistviewlist__lineitem {
    position: relative;
    margin-bottom: 10px;
}

.quotelistviewlist__configuration dl {
    max-width: 100%;
    white-space: normal;
    display: flex;
}

.quotelistviewlist__configuration dd:nth-of-type(1) {
	margin-left: 5px;
    flex: 1;
}

.quotelistviewlist__product-total {
    background-color: #217AD9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 4px 4px;
    padding: 12px 20px;
    float: right;
}

.quotelistviewlist__product-total .heading--6 {
    opacity: .75;
    margin-bottom: 1px;
}

.quotelistviewlist__product-total .heading--5 {
    margin-bottom: 3px;
}

.quotelistviewlist__summary {
    background-color: white;
    border-radius: 4px;
	width: 100%;
	margin: 35px 0 0;
}

.quotelistview__summary-section {
	position: relative;
	margin: 0 20px;
	padding: 30px 0;
	border-top: 1px solid #F0F3F6;
}
.quotelistview__summary-section:nth-of-type(1) {
	border: none;
}
.quotelistview__summary-section:nth-last-of-type(1) {
	margin: 0;
	padding: 30px 20px;
}

.quotelistview__summary-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.quotelistview__summary-item > :nth-child(1) {
	flex: 1;
	margin-right: 15px;
}

.quotelistview__summary-section--discounts .quotelistview__summary-item {
	margin: 5px 0 0;
}

.quotelistview__summary-total {
	font-size: 1.75rem;
    color: #217AD9;
}

.quotelistview__summary-date {
	margin: 5px 0 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.quotelistviewlist {
		padding: 12px 8%;
	}

    .quotelistviewlist__product-total {
    	width: 400px;
	}
	.quotelistviewlist__summary {
        width: 400px;
        float: right;
    }
}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}