.personalsettingsoverlay {

}

.personalsettingsoverlay_pplist {
	
}

.personalsettingsoverlay_pplist-entry {
	margin-top: 15px;
}

.personalsettingsoverlay_pplist-entry--labels {
	display: none;
}

.personalsettingsoverlay_pplist-entry:nth-last-of-type(1) {
	margin-bottom: 15px;
}

.personalsettingsoverlay_pplist-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-top: 6px;
}

.personalsettingsoverlay_pplist-group > .input {
	flex: 1;
}

.personalsettingsoverlay_pplist-group > .actionbtn {
	margin-left: 6px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.personalsettingsoverlay_pplist-entry {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 8px 0 0;
	}

	.personalsettingsoverlay_pplist-entry--labels {
		margin: 0;
	}

	.personalsettingsoverlay_pplist-group {
		margin-top: 0;
	}

	.personalsettingsoverlay_pplist-group:nth-child(1) {
		width: 300px; 
	}

	.personalsettingsoverlay_pplist-group:nth-child(2) {
		flex: 1;
		margin-left: 10px;
	}
}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}