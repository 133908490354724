.dashboard {
	
}

.dashboard__create,
.dashboard__quote-search {
	margin: 20px 0 0;
}

.dashboard > .contentbox1:nth-of-type(2) {
	margin: 20px 0 0;
}

.dashboard > .contentbox1:nth-of-type(2) .contentbox1__header {
	display: block;
}

.dashboard__quote {
	border-top: 1px solid #EAEEF3;
	padding: 20px 0;
}

.dashboard__quote:nth-of-type(1) {
	margin: 25px 0 0;
}

.dashboard__quote:nth-last-of-type(1) {
	padding-bottom: 0;
}

.dashboard__quote-title {
	line-height: normal;
}

.dashboard__quote-address {
	margin-top: 5px;
	color: #646262;
	line-height: normal;
}

.dashboard__quote-date {
	margin: 18px 0 18px;
}

.dashboard__quote .btn {
	width: 100%;
}

.dashboard__quote .btn--primary {
	margin-top: 10px;
}

.dashboard__create-search-results {
	position: relative;
	z-index: 2;
	display: block;
}

.dashboard__create-search-results-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 250px;
	overflow: auto;
	padding: 20px;
	background: #FFFFFF;
	box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
	border-radius: 0 0 4px 4px;
}

.dashboard__create-search-item {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.dashboard__create-search-item:nth-of-type(1) {
	margin: 0;
}

.dashboard__create-search-item-content {
	margin: 0 0 15px;
}

.dashboard__create-search-item-people {
    margin-top: 5px;
    color: #646262;
}

.dashboard__create--open .input--1 {
	background-color: white;
	border-top: 1px white solid !important;
	border-left: 1px white solid !important;
	border-right: 1px white solid !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.dashboard__create {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	
	.dashboard__quote {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.dashboard__quote-content {
		flex: 1;
	}
	.dashboard__quote-date {
		margin: 0 35px;
	}
	.dashboard__quote .btn {
		width: auto;
	}
	.dashboard__quote .btn--primary {
		margin: 0 0 0 10px;
	}

	.dashboard__create-search-item {
		flex-direction: row;
		align-items: center;
	}

	.dashboard__create-search-item-content {
		flex: 1;
		margin: 0 20px 0 0;
	}

}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}