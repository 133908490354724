.login__header {
    margin-bottom: 25px;
}

.login__header .heading--6 {
    margin-bottom: 3px;
}

.login__secondary {
    margin: 15px 0 35px 0;
    display: flex;
    justify-content: space-between;
}

.login .input--1:nth-of-type(2) {
    margin-top: 10px;
}