@tailwind base;
@tailwind components;
@tailwind utilities;

@charset "utf-8";

/* Font Awesome Font Family */
@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/font-awesome/fa-brands-400.eot');
	src: url('./fonts/font-awesome/fa-brands-400.eot?#iefix') format('embedded-opentype'),
		url('./fonts/font-awesome/fa-brands-400.woff2') format('woff2'),
		url('./fonts/font-awesome/fa-brands-400.woff') format('woff'),
		url('./fonts/font-awesome/fa-brands-400.ttf') format('truetype'),
		url('./fonts/font-awesome/fa-brands-400.svg#fontawesome') format('svg');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/font-awesome/fa-light-300.eot');
	src: url('./fonts/font-awesome/fa-light-300.eot?#iefix') format('embedded-opentype'),
		url('./fonts/font-awesome/fa-light-300.woff2') format('woff2'),
		url('./fonts/font-awesome/fa-light-300.woff') format('woff'),
		url('./fonts/font-awesome/fa-light-300.ttf') format('truetype'),
		url('./fonts/font-awesome/fa-light-300.svg#fontawesome') format('svg');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/font-awesome/fa-regular-400.eot');
	src: url('./fonts/font-awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'),
		url('./fonts/font-awesome/fa-regular-400.woff2') format('woff2'),
		url('./fonts/font-awesome/fa-regular-400.woff') format('woff'),
		url('./fonts/font-awesome/fa-regular-400.ttf') format('truetype'),
		url('./fonts/font-awesome/fa-regular-400.svg#fontawesome') format('svg');
}
@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/font-awesome/fa-solid-900.eot');
	src: url('./fonts/font-awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'),
		url('./fonts/font-awesome/fa-solid-900.woff2') format('woff2'),
		url('./fonts/font-awesome/fa-solid-900.woff') format('woff'),
		url('./fonts/font-awesome/fa-solid-900.ttf') format('truetype'),
		url('./fonts/font-awesome/fa-solid-900.svg#fontawesome') format('svg');
}

/* Reset Styles */
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	border: 0;
	line-height: inherit;
	color: inherit;
	text-transform: inherit;
	font-weight: inherit;
	font-style: inherit;
	font-size: inherit;
	font-family: inherit;
	background-color: transparent;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
}
ol,
ul {
	list-style: none inside none;
}
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption,
th,
td {
	text-align: left;
	font-weight: inherit;
}
blockquote::before,
blockquote::after,
q::before,
q::after {
	content: '';
}
blockquote,
q {
	quotes: '' '';
}
button {
	cursor: pointer;
}
button:focus,
button:active {
	outline: 0;
}
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
	padding: 0;
	border: 0;
	margin: 0;
	line-height: normal;
}
header,
footer,
nav,
article,
section,
main {
	display: block;
}
:-ms-input-placeholder {
	color: #5a6a77;
	opacity: 1;
}
::-webkit-input-placeholder {
	color: #5a6a77;
	opacity: 1;
}
::-moz-placeholder {
	color: #5a6a77;
	opacity: 1;
}
::-ms-clear {
	display: none;
}
select.__placeholder {
	color: #5a6a77;
	opacity: 1;
}
input,
textarea,
select {
	background: none;
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	line-height: normal;
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	resize: none;
}
textarea {
	height: 100%;
}
select::-ms-expand {
	display: none;
}

/*- Extra Small Devices, Phones -*/
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets -*/
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/*---------------------------------------------------
Core Elements  
- styles used through out the project
-----------------------------------------------------*/
html {
	font-size: 16px;
	font-family: 'Lato', sans-serif;
	-webkit-text-size-adjust: 100%;
	min-height: -webkit-fill-available;
}

body {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f1f1f1;
	line-height: 1.3;
	min-height: -webkit-fill-available;
}

/*-------------------------
Activity Indicator
---------------------------*/
.activity-indicator {
	z-index: 15000;
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: 77px;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #062465;
	border-radius: 50%;
}

.activity-indicator:before {
	position: relative;
	z-index: 2;
	content: '\f3f4';
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
	border-radius: 50%;
	-webkit-animation: indicatorSpin 1.25s infinite linear;
	animation: indicatorSpin 1.25s infinite linear;
}

.activity-indicator:after {
	content: '';
	position: absolute;
	z-index: 1;
	width: 46px;
	height: 46px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 5px solid #1f3f83;
	border-radius: 50%;
}

@-webkit-keyframes indicatorSpin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes indicatorSpin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}

/*-------------------------
Heading Styles
---------------------------*/

.heading {
}

.heading--2 {
	font-weight: 400;
	font-size: 1.375rem;
	line-height: 1.6875;
	color: #221f20;
}

.heading--3 {
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.5;
	color: #221f20;
}

.heading--4 {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 1.375;
	color: #221f20;
}

.heading--5,
.heading--form-element-1 {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.1875;
	color: #221f20;
}

.heading--secondary {
	color: #7a7979;
}

.heading--5-paragraph {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.625;
	color: #221f20;
}

.heading--6,
.heading--form-element-2 {
	font-weight: 700;
	font-size: 0.75rem;
	line-height: 0.9375;
	color: #908f8f;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.heading--7 {
	font-weight: 400;
	font-size: 0.8125rem;
	line-height: 1;
	color: #646262;
}

.heading--8 {
	font-weight: 700;
	color: #8a8b8d;
	line-height: 1.1875;
}

.heading--9 {
	font-weight: 700;
	font-size: 0.875rem;
	color: #8a8b8d;
	line-height: 1.1875;
}

.heading--10 {
	font-size: 0.875rem;
	color: #221f20;
	line-height: 1.0625;
}

.heading--11 {
	font-size: 1.25rem;
	font-weight: 700;
	color: #221f20;
}

.heading--12 {
	font-size: 0.875rem;
	font-weight: 700;
	color: #221f20;
	line-height: 1.5;
}

.heading--13 {
	font-size: 0.875rem;
	font-weight: 400;
	color: #221f20;
	line-height: 1.5;
}

.heading--white {
	color: white;
}

.heading--active {
	color: #217ad9;
}

.heading--inactive {
	color: #a2a2a2;
}

.heading--form-element-1,
.heading--form-element-2 {
	display: inline-block;
	margin-bottom: 6px;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}

/*-------------------------
icon fonts
---------------------------*/
/*Use one of the following classes on any item that has an icon :before or :after*/

/*Font Awesome - https://fontawesome.com*/
.icon-fab:before,
.icon-fab:after,
.icon-fal:before,
.icon-fal:after,
.icon-far:before,
.icon-far:after,
.icon-fas:before,
.icon-fas:after {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.icon-fab:before,
.icon-fab:after {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 300;
} /* Font Awwsome Brands */
.icon-fal:before,
.icon-fal:after {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
} /* Font Awwsome Light */
.icon-far:before,
.icon-far:after {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
} /* Font Awwsome Regular */
.icon-fas:before,
.icon-fas:after {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
} /* Font Awwsome Solid */

/*-------------------------
icon
---------------------------*/
.icon:before {
	display: inline-block;
	margin: 0 0 0 0;
}

.icon--white {
	color: #fff;
	transition: 0.2s;
}

.icon--white:hover {
	color: #217ad9;
}

.icon--white-nohover:hover {
	color: #ffffff;
}

.icon--dark {
	color: #221f20;
	transition: 0.2s;
}

.icon--dark:hover {
	opacity: 0.5;
}

.icon--grip-lines:before {
	content: '\f7a4';
}

.icon--duplicate:before {
	content: '\f0c5';
}

.icon--delete:before {
	content: '\f1f8';
}

.icon--check:before {
	content: '\f00c';
}

.icon--plus-circle:before {
	content: '\f055';
}

.icon--back:before {
	content: '\f053';
}

.icon--file:before {
	content: '\f15c';
}

.icon--notes:before {
	content: '\f4a6';
}

.icon--pencil:before {
	content: '\f040';
}

.icon--rename:before {
	content: '\f044';
}

.icon--person:before {
	content: '\f007';
}

.icon--forward:before {
	content: '\f054';
}

.icon--more:before {
	content: '\f141';
}

.icon--email:before {
	content: '\f0e0';
}

.icon--phone:before {
	content: '\f095';
}

.icon--dropdown:before {
	content: '\f078';
}

.icon--dropdown-open:before {
	content: '\f077';
}

.icon--list:before {
	content: '\f03a';
}

.icon--plus:before {
	content: '\f067';
}

.icon--close:before {
	content: '\f00d';
}

.icon--save:before {
	content: '\f0c7';
}

.icon--present:before {
	content: '\f685';
}

.icon--build:before {
	content: '\f6e3';
}

.icon--globe:before {
	content: '\f57d';
}

.icon--camera:before {
	content: '\f030';
}

.icon--x:before {
	content: '\f00d';
}

.icon--ruler:before {
	content: '\f545';
}

.icon--minus:before {
	content: '\f068';
}

.icon--expand:before {
	content: '\f065';
}

.icon--large:before {
	font-size: 1.25rem;
}

.icon--print:before {
	content: '\f02f';
}

.icon--email:before {
	content: '\f0e0';
}

.icon--error:before {
	content: '\f071';
}

.icon--grid:before {
	content: '\f009';
}

.icon--list:before {
	content: '\f03a';
}

.icon--usercog:before {
	content: '\f4fe';
}

/*-------------------------
Generic Content
---------------------------*/
.generic-content p {
	margin: 20px 0 0;
}

.generic-content > :nth-child(1) {
	margin-top: 0;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}

/*-------------------------
Configuration Elements
---------------------------*/

/* config heading */
.config-heading {
	display: block;
	margin: 0 0 15px;
}
.config-subheading {
	display: block;
	margin: 0 0 10px;
}

/* divider line */
.config-divider {
	height: 1px;
	background-color: #ebebeb;
	margin: 25px 0px;
}

/* Option Select */
.config-option-select {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	margin-top: -15px;
}

.config-option-select input {
	display: none;
}

.config-option-select__option {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	margin: 15px 10px 0 0;
	min-width: 205px;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	background: #f4f7fb;
	overflow: hidden;
}

.config-option-select__material__option {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	margin: 0px 10px 0 0;
	min-width: 205px;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	background: #f4f7fb;
	overflow: hidden;
}

.config-option-select__option--inactive {
	opacity: 0.25;
	cursor: not-allowed !important;
}

.config-option-select__check {
	border-right: 1px solid rgba(0, 0, 0, 0.08);
	height: 50px;
	width: 50px;
	transition: 0.1s;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.config-option-select__checked {
	border-right: 1px solid rgba(0, 0, 0, 0.08);
	height: 50px;
	width: 50px;
	transition: 0.1s;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 1px solid #217ad9;
	background: #217ad9;
}

.config-option-select__not_checked {
	border-right: 1px solid rgba(0, 0, 0, 0.08);
	height: 50px;
	width: 50px;
	transition: 0.1s;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.config-option-select input[type='radio']:checked + .config-option-select__option,
.config-option-select input[type='checkbox']:checked + .config-option-select__option {
	border: 1px solid #217ad9;
	background: #217ad9;
}

.config-option-select
	input[type='radio']:checked
	+ .config-option-select__option
	.config-option-select__check:before,
.config-option-select
	input[type='checkbox']:checked
	+ .config-option-select__option
	.config-option-select__check:before {
	content: '\f00c';
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
	color: #fff;
}

.config-option-select__info {
	display: flex;
	align-items: center;
	background-color: #fff;
	font-size: 0.9375rem;
	color: #221f20;
	text-align: left;
	white-space: nowrap;
	flex: 1;
	padding: 15px;
}

.config-option-select
	input[type='radio']:checked
	+ .config-option-select__option
	.config-option-select__info,
.config-option-select
	input[type='checkbox']:checked
	+ .config-option-select__option
	.config-option-select__info {
	color: #217ad9;
}

/* Notch Select */
.config-notch-select {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: -10px;
}

.config-notch-select input[type='radio'] {
	display: none;
}

.config-notch-select__option {
	margin: 10px 10px 0 0;
	width: calc(50% - 10px);
	cursor: pointer;
	position: relative;
}

.config-notch-select__option--inactive {
	opacity: 0.25;
	cursor: not-allowed !important;
}

.config-notch-select__button {
	height: 40px;
	border: 1px solid #d3d2d2;
	width: 100%;
	transition: 0.15s;
	position: relative;
}

.config-notch-select
	input[type='radio']:checked
	+ .config-notch-select__option
	.config-notch-select__button {
	border: 1px solid #217ad9;
}

.config-notch-select
	input[type='radio']:checked
	+ .config-notch-select__option
	.config-notch-select__button:before {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
	font-size: 0.875rem;
	padding-left: 5px;
	padding-top: 2px;
	content: '\f00c';
	position: absolute;
	color: white;
	height: 21px;
	width: 25px;
	background-color: #217ad9;
	right: 0;
	top: 0;
	border-radius: 0 0 0 4px;
	transition: 0.15s;
}

.config-notch-select__button img {
	display: block;
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.config-notch-select__label {
	margin-top: 4px;
	transition: 0.15s;
}

.config-notch-select
	input[type='radio']:checked
	+ .config-notch-select__option
	.config-notch-select__label {
	color: #217ad9;
}

.config-notch-select__notch:before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 0;
	border-color: #d3d2d2 transparent transparent transparent;
	position: absolute;
	top: -1px;
	left: -1px;
	transition: 0.15s;
	z-index: 1;
}

.config-notch-select__notch:after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 10px 0 0;
	border-color: #ffffff transparent transparent transparent;
	position: absolute;
	top: -2px;
	left: -2px;
	transition: 0.15s;
	z-index: 2;
}

.config-notch-select
	input[type='radio']:checked
	+ .config-notch-select__option
	.config-notch-select__notch:before {
	border-color: #217ad9 transparent transparent transparent;
}

/* Circle Select */
.config-circle-select {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -30px 0 0;
}

.config-circle-select input[type='radio'] {
	display: none;
}

.config-circle-select__option {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px 30px 0 0;
}

.config-circle-select__option--inactive {
	opacity: 0.25;
	cursor: not-allowed !important;
}

.config-circle-select__circle {
	height: 100px;
	width: 100px;
	border: 0.5px solid #d3d2d2;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	transition: 0.15s;
}

.config-circle-select
	input[type='radio']:checked
	+ .config-circle-select__option
	.config-circle-select__circle,
.config-circle-select
	input[type='radio']:checked
	+ .config-circle-select__option
	.config-circle-select__circle--none:after {
	border: 0.5px solid #217ad9;
}

.config-circle-select__circle--none:after {
	content: '';
	position: absolute;
	border-top: 0.5px solid #d3d2d2;
	width: 100px;
	transform: rotate(45deg);
	transform-origin: -10px 23px;
	transition: 0.15s;
}

.config-circle-select
	input[type='radio']:checked
	+ .config-circle-select__option
	.config-circle-select__circle:before {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
	font-size: 0.875rem;
	padding-left: 5px;
	padding-top: 11px;
	content: '\f00c';
	position: absolute;
	color: white;
	height: 31px;
	width: 38px;
	background-color: #217ad9;
	right: 0;
	top: 0;
	border-radius: 4px;
	transition: 0.15s;
}

.config-circle-select__circle img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: center;
	object-position: center;
}

.config-circle-select__label {
	max-width: 105px;
	text-align: center;
	margin-top: 5px;
	transition: 0.15s;
}

.config-circle-select
	input[type='radio']:checked
	+ .config-circle-select__option
	.config-circle-select__label {
	color: #217ad9;
}

/* select config */
.config-select-field {
}

/* number config */
.config-number-field .input {
	max-width: 110px;
}

/* incremental number */
.config-incremental-number-field {
}

/* incremental number */
.config-text-field .input {
	max-width: 500px;
}

/* boolean config */
.config-boolean-field {
	margin: 20px 0 0;
}

/* checkboxs config */
.config-checkboxes {
}

.config-checkboxes__checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 20px 0 0;
}

.config-checkboxes__checkbox:nth-of-type(1) {
	margin: 0;
}

.config-checkboxes__box {
	height: 40px;
	width: 40px;
	border-radius: 4px;
	background-color: #f3f7ff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.config-checkboxes
	input[type='checkbox']:checked
	+ .config-checkboxes__checkbox
	.config-checkboxes__box {
	background-color: #217ad9;
}

.config-checkboxes
	input[type='checkbox']:checked
	+ .config-checkboxes__checkbox
	.config-checkboxes__box:before {
	content: '\f00c';
	color: #fff;
	font-size: 1.125rem;
}

.config-checkboxes__label {
	margin: 0 0 0 10px;
	flex: 1;
	font-size: 1rem;
	line-height: normal;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.config-boolean-field {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		max-width: 600px;
	}
	.config-boolean-field > .config-heading {
		flex: 1;
		margin: 0 20px 0 0;
	}
	.config-notch-select__option {
		width: 150px;
	}
	.config-incremental-number-field {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		max-width: 600px;
	}
	.config-incremental-number-field > .config-heading {
		flex: 1;
		margin: 0 20px 0 0;
	}
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}
