.quotedetailactionbar {
	
}

.quotedetailactionbar__create {
    position: relative;
    background-color: #445A8B;
    border-radius: 4px 4px 0px 0px;
    font-size: 1rem;
    color: white;
    padding: 12px 22px;
	transition: .2s;
	height: 45px;
	margin: 0 0 0 1px;
}

.quotedetailactionbar__create:hover {
    opacity: .8;
}

.quotedetailactionbar__quotes {
    display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	flex-wrap: nowrap;
	background-color: #062465;
}

.quotedetailactionbar__quote {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 15px 8px 19px;
    border-radius: 4px 4px 0px 0px;
	background-color: #D8DFEC;
	min-width: 100px;
	max-width: 100px;
	height: 45px;
	margin: 0 0 0 1px;
}

.quotedetailactionbar__quote--active {
	min-width: 210px !important;
	max-width: 210px !important;
	background-color: white;
	box-shadow: 0px -8px 8px 1px rgba(6,36,101,0.15);
	z-index: 2;
	height: 50px;
}

.quotedetailactionbar__quote.quotedetailactionbar__quote--active .submenu .iconbtn--primary:hover,
.quotedetailactionbar__quote.quotedetailactionbar__quote--active .submenu.submenu--open .iconbtn--primary,
.quotedetailactionbar__quote .submenu .iconbtn--primary {
    background-color: #D8DFEC;
}

.quotedetailactionbar__quote .submenu .iconbtn--primary:hover,
.quotedetailactionbar__quote .submenu--open .iconbtn--primary,
.quotedetailactionbar__quote.quotedetailactionbar__quote--active .submenu .iconbtn--primary {
    background-color: #fff;
}

.quotedetailactionbar__quote > .heading {
    white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 10px 0 0;
}

.quotedetailactionbar__actions {
	padding: 25px 20px;
	background-color: white;
	border-bottom: 1px solid #EFF2F6;
	position: relative;
	z-index: 1;
}

.quotedetailactionbar__btns {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 20px 0 0;
}

.quotedetailactionbar__btns--presentation {
	justify-content: center;
}

.quotedetailactionbar__btns .actionbtn {
	margin-left: 10px;
}

.quotedetailactionbar__btns .btn {
	margin-left: 10px;
}

.quotedetailactionbar__btns .btn--secondary {
	flex: 1;
}

.quotedetailactionbar__btns .btn--primary {
	margin-top: 10px;
	width: 100%;
}

.quotedetailactionbar__btns :nth-child(1) {
	margin-left: 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.quotedetailactionbar__actions {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.quotedetailactionbar__info {
		flex: 1;
		margin-right: 20px;
	}

	.quotedetailactionbar__btns {
		margin: 0;
		justify-content: flex-start;
		flex-wrap: nowrap;
	}

	.quotedetailactionbar__btns .btn {
		margin-top: 0;
		flex: unset;
		width: auto;
	}
}
@media only screen and (min-width: 992px) {
	.quotedetailactionbar__quote {
		min-width: 175px;
		max-width: 175px;
	}
}
@media only screen and (min-width: 1200px) {}