.grid-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
}

.grid-container table {
	font-family: arial, sans-serif;
	/* border-collapse: collapse; */
}

.grid-container td,
.grid-container th {
	text-align: left;
}

.grid-container .droptarget {
  display: block;
	height: 100%;
  width: 100%;
}
.input--1 input,
.input--1 textarea,
.input--1 select {
	padding: 14px 15px 15px;
}
.custom-window_layout .productconfiguration {
	margin: 10px 0 0;
}
.custom-window_layout .window-size-container {
  background-color: #fff;
  padding: 1rem 2rem;
}
.custom-window_layout .window-size-inputs-container {
  display: flex;
  align-content: center;
  align-items: flex-end;
  gap: 2rem;
}
.custom-window_layout .window-size-inputs-container .productconfiguration__windowsize-output-calculation {
  padding: 14px 12px 0 12px;
} 
