.quotedetailproductlist {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
}

.quotedetailproductlist__scroller {
	flex: 1;
	margin-bottom: 45px;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.quotedetailproductlist__table {
	min-width: 100%;
}

.quotedetailproductlist__head tr {
	display: flex;
	flex-direction: row;
	padding: 0 10px;
	background-color: #fff;
}

.multiunitproductlist__row {
	margin: 15px 0px 10px;
	width: 100%;
}

.quotedetailproductlist__body tr {
	display: flex;
	flex-direction: row;
	margin: 0px 10px;
	border-radius: 4px;
	overflow: hidden;
}

.ddcontainer {
	width: 100%;
}

.ddcontainersource {
	margin-bottom: 0px !important;
	margin-top: 5px !important;
	padding: 0px;
	width: 98.5%;
}

.quotedetailproductlist__head th {
	padding: 12px 0;
}

.quotedetailproductlist__body td {
	display: flex;
	background-color: #fff;
}

.quotedetailproductlist__head th:nth-of-type(1) {
	padding-left: 20px;
}

.quotedetailproductlist__head th:nth-of-type(1),
.quotedetailproductlist__body td:nth-of-type(1) {
	width: 350px;
}

.quotedetailproductlist__head th:nth-of-type(2),
.quotedetailproductlist__body td:nth-of-type(2) {
	min-width: 350px;
	padding-left: 20px;
	padding-right: 20px;
	border-left: 1px solid transparent;
	flex: 1;
}

.quotedetailproductlist__body td:nth-of-type(2) {
	border-color: #f3f6f8;
}

.quotedetailproductlist__head th:nth-last-of-type(1),
.quotedetailproductlist__head th:nth-last-of-type(2),
.quotedetailproductlist__head th:nth-last-of-type(3),
.quotedetailproductlist__body td:nth-last-of-type(1),
.quotedetailproductlist__body td:nth-last-of-type(2),
.quotedetailproductlist__body td:nth-last-of-type(3) {
	border-left: 5px solid #fff;
	width: 120px;
	text-align: center;
}

.quotedetailproductlist__head th:nth-last-of-type(1),
.quotedetailproductlist__body td:nth-last-of-type(1) {
	border-right: 15px solid #fff;
	width: 131px;
}

.quotedetailproductlist__empty {
	width: 100% !important;
	padding: 24px 15px !important;
	border-right: 0 !important;
}

.quotedetailproductlist__number {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 2px;
	min-width: 2.5rem;
	background-color: #f4f7fb;
	border-radius: 4px 0 0 4px;
}

.quotedetailproductlist__image {
	width: 130px;
	height: 130px;
	position: relative;
}
.quotedetailproductlist__image img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.quotedetailproductlist__header {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.quotedetailproductlist__overview {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 105px;
	margin: 24px 0;
}

.quotedetailproductlist__overview .input {
	margin-top: 2px;
}

.quotedetailproductlist__actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.quotedetailproductlist__actions .iconbtn {
	margin-left: 20px;
}

.quotedetailproductlist__actions .iconbtn:nth-of-type(1) {
	margin-left: 0;
}

.quotedetailproductlist__configuration {
	margin: 24px 0;
	width: 100%;
}

.quotedetailproductlist__configuration dt {
	float: left;
	margin: 0 3px 0 0;
}

.quotedetailproductlist__text-link {
	text-decoration: underline;
}

.quotedetailproductlist__spec-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 16px 0;
	padding: 0 5px;
	background-color: #f4f7fb;
	width: 100%;
}

.quotedetailproductlist__summary {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: white;
	width: 100%;
	padding: 18px 20px;
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	border-top: 1px solid #f0f3f6;
	z-index: 1000;
}

.quotedetailproductlist__summary span {
	width: calc((100% - 30px) / 2);
	text-align: center;
	margin: 10px 0 0;
}

.quotedetailproductlist__summary span:nth-of-type(1),
.quotedetailproductlist__summary span:nth-of-type(2) {
	margin-top: 0;
}

@media only screen and (min-width: 768px) {
	.quotedetailproductlist__summary {
		justify-content: flex-start;
	}
	.quotedetailproductlist__summary span {
		width: auto;
		text-align: left;
		margin: 0 0 0 24px;
	}
	.quotedetailproductlist__summary span:nth-of-type(1) {
		margin: 0;
	}
}
