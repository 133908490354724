.btn {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 5px 50px;
	min-height: 50px;
	font-weight: 700;
	font-size: 0.875rem;
	text-transform: uppercase;
	border-radius: 4px;
	border: #217ad9 1px solid;
	transition: 0.2s;
	letter-spacing: 0.35px;
	width: auto;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;
}

.btn:hover {
	opacity: 0.8;
}

.btn--primary {
	color: white !important;
	background-color: #217ad9;
}

.btn--small {
	padding: 15px 18px 15px 18px;
}

.btn--medium {
	padding: 16px 30px 17px 30px;
	color: #217ad9;
}

.btn--secondary {
	color: #217ad9;
	background-color: white;
}

.btn--tertiary {
	background-color: #f0f0f0;
	color: #a2a2a2;
	border: none;
}

.btn--danger {
	background-color: white;
	border-color: red;
	color: red;
}

.btn--danger--filled {
	background-color: red;
	border-color: red;
	color: white;
}

.btn--text {
	color: #217ad9;
	font-weight: 400;
	font-size: 1rem;
	text-decoration: none;
	transition: 0.2s;
	cursor: pointer;
	text-align: left;
	padding: 0 0 0 0;
	border: 0;
	text-transform: none;
	min-height: auto;
}

.btn--100 {
	width: 100%;
}

.btn--text:hover {
	color: rgb(23, 85, 151);
}

.btn--text-underline {
	font-weight: 700;
	font-size: 0.75rem;
	color: #217ad9;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	text-decoration: underline;
	border: none;
	padding: 0;
	margin: 0;
	height: auto;
	min-height: auto;
}

.btn .icon {
	margin: 0 7px 0 0;
}

/* Responsive Design */
@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}
