.presentationbody {
	display: flex;
	flex-direction: row-reverse;
	height: 100%;
	jusitfy-content: flex-end;
	padding: 15px;
	width: 100%;
}

.presentationbody__total-box {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 22px 0 26px;
	box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
	border-top: 4px solid #217AD9; 
	border-radius: 4px;
}

.presentationbody__total-box--savings {
	margin: 10px 0 0;
	border-color: #24A107;
}

.presentationbody__total-box-heading {
	font-size: 3rem;
	margin: 0 0 7px;
}

.presentationbody__show-discounts {
	margin: 20px 0 0;
}

.presentationbody__discounts {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 25px 0 0;
	width: 100%;
}

.presentationbody__discount {
	width: 100%;
	background-color: white;
    border-radius: 4px;
	padding: 25px 20px;
	margin: 10px 0 0;
}

.presentationbody__discount--active {
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
}

.presentationbody__discount:nth-of-type(1) {
	margin-top: 15px;
}

.presentationbody__discount__strong {
	margin: 0 0 3px;
}

.presentationbody__discount-btn,
.presentationbody__discount-info {
	margin: 10px 0 0;
}

.presentationbody__discount-info:nth-of-type(1) {
	margin: 0;
}

.presentationbody__discount-btn {
	width: 165px;
}

.presentationbody__add-discount {
	align-self: flex-end;
	margin: 10px 0 0;
}

.presentationbody__payment-options {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-direction: flex-start;
	border-top: 1px solid #E0E3E7;
	margin: 45px 0 0;
	padding: 30px 0 0;
	width: 100%;
}

.presentationbody__payment {
	display: flex;
    flex-direction: column;
	margin: 15px 0 0;
	width: 100%;
}

.presentationbody__payment-input {
	display: none;
}

.presentationbody__payment-button {
	display: block;
	background: #FFFFFF;
    border: 1px solid rgba(34,31,32,0.20);
    border-radius: 4px;
	transition: .2s;
	cursor: pointer;
	margin: 10px 0 0;
	overflow: hidden;
}

.presentationbody__payment-button:nth-of-type(1) {
	margin-top: 0;
}

.presentationbody__payment-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	transition: .2s;
	border-bottom: 1px solid #D3D2D2;
}

.presentationbody__payment-check {
	height: 55px;
	width: 55px;
	display: flex; 
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: .2s;
	border-right: 1px solid #D3D2D2;
	background-color: #F4F7FB;
}

.presentationbody__payment-check .icon--check {
    color: #F5F7F9;
}

.presentationbody__payment input[type="radio"]:checked + .presentationbody__payment-button,
.presentationbody__payment input[type="radio"]:checked + .presentationbody__payment-button .presentationbody__payment-head,
.presentationbody__payment input[type="radio"]:checked + .presentationbody__payment-button .presentationbody__payment-check {
    border-color: #217AD9;
}

.presentationbody__payment input[type="radio"]:checked + .presentationbody__payment-button .presentationbody__payment-check {
	background-color: #217AD9;
}

.presentationbody__payment input[type="radio"]:checked + .presentationbody__payment-button .presentationbody__payment-check .icon--check {
	color: #fff;
}

.presentationbody__payment-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	padding: 0 15px;
}

.presentationbody__payment-title .iconbtn {
	margin: 0 0 0 10px;
	min-width: 20px;
	min-height: 20px;
	padding: 0;
}

.presentationbody__payment-title .input--select-text {
	width: auto;
	color: #2385E2;
	margin: 0 0 0 5px;
}

.presentationbody__payment-title .input--select-text:after {
	color: #2385E2;
}

.presentationbody__payment-body {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 15px 20px 30px;
	width: 100%;
}

.presentationbody__payment-heading {
	font-size: 3rem;
	margin-bottom: 4px;
}

.presentationbody__continue {
	align-self: flex-end;
	margin: 40px 0 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {
	.presentationbody__total {
		display: flex;
		width: 40%;
	}
	.presentationbody__total-box {
		flex: 1;
	}
	.presentationbody__total-box--savings {
		flex: unset;
		width: 32%;
		min-width: 257px;
		margin: 0 0 0 10px;
	}
	.presentationbody__discount {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.presentationbody__discount-info {
		text-align: right;
		margin: 0 20px 0 0 !important;
	}
	.presentationbody__discount-info:nth-of-type(1) {
		text-align: left;
		flex: 1;
	}
	.presentationbody__discount-btn {
		margin: 0;
	}
	.presentationbody__payment {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}
	.presentationbody__payment-button {
		//width: calc((100% - 10px) / 2);
		width: 100%;
		margin: 10px 0px;
	}
	.presentationbody__payment-button:nth-of-type(1),
	.presentationbody__payment-button:nth-of-type(2) {
		margin-top: 0;
	}
	.presentationbody__payment-button:nth-of-type(2n) {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 1200px) {}