.productconfigurationsidebar {
	border-bottom: 1px solid #ebebeb;
	padding: 0 20px 20px 20px;
	text-align: center;
	position: relative;
}

.productconfigurationsidebar__image {
	position: relative;
	overflow: hidden;
	height: 0;
	padding-top: 90.25%;
}

.productconfigurationsidebar__image-file {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center center;
}

.productconfigurationsidebar .iconbtn {
	position: absolute;
   	right: 20px;
    bottom: 12px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}