.userwelcome {
	
}

.userwelcome__account {
	border-bottom: 1px #EAEEF3 solid;
	padding: 30px 20px 30px 20px;
}

.userwelcome .heading--6 {
	margin-bottom: 2px;
}

.userwelcome__link {
	margin: 10px 0 0;
}

.userwelcome__actions {
	padding: 30px 20px;
}

.userwelcome__action {
	color: #217AD9;
}
.userwelcome__action:before {
	margin: 0 8px 0 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}