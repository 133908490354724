.simplenavbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 10px 0;
}

.simplenavbar .heading--3 {
	flex: 1;
	margin-left: 38px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}