.productphotooverlay {
	width: 100vw;
	max-width: 90vw;
}

.productphotooverlay__content {
    background-color: white;
}



.productphotooverlay__gallery {
    padding: 20px 10px;
}

.productphotooverlay__gallery-viewer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.productphotooverlay__gallery-btn-container {
	width: 42px;
}

.productphotooverlay__gallery-btn {
	display: block;
	width: 100%;
    background-color: #F4F7FB;
    border-radius: 4px;
    font-size: 2rem;
    color: #8A8B8D;
    padding: 28px 0;
	transition: .2s;
	line-height: 0;
}

.productphotooverlay__gallery-btn:hover {
    color: #217AD9;
}

.productphotooverlay__gallery-image {
	flex: 1;
}

.productphotooverlay__gallery-image-file {
	position: relative;
	padding-top: 100%;
	height: 0;
	overflow: hidden;
}

.productphotooverlay__gallery-image-file img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.productphotooverlay__gallery-thumbnails {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	margin: 5px 0 0;
}

.productphotooverlay__gallery-thumbnail {
	position: relative;
	background-color: #fff;
	width: 50px;
	height: 50px;
	border-radius: 4px;
	border: 1px solid #ebebeb;
	transition: .2s;
	overflow: hidden;
	margin: 15px 7px 0 7px;
}

.productphotooverlay__gallery-thumbnail img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	border: 1px solid transparent;
	transition: .2s;
	object-position: contain;
	object-fit: center;
}

.productphotooverlay__gallery-thumbnail:hover {
	border: 1px solid rgb(139, 182, 228);
}

.productphotooverlay__gallery-thumbnail--active {
	box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
}

.productphotooverlay__gallery-thumbnail--active,
.productphotooverlay__gallery-thumbnail--active img {
	border: 1px solid #217AD9 !important;
}

.productphotooverlay__info {
    background-color: #F4F7FB;
    padding: 35px;
    flex: 1;
    border-radius: 0 4px 4px 0;
}

.productphotooverlay__info-title {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 25px;
	margin-bottom: 25px;
}

@media only screen and (min-width: 480px) {
    .productphotooverlay__gallery {
        padding: 25px 25px;
    }
}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {
	.productphotooverlay__content {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
	}
	.productphotooverlay__gallery {
		padding: 65px 25px 35px 25px;
		width: 50%;	
	}
	.productphotooverlay__info {
		flex: 1;
	}
}
@media only screen and (min-width: 1200px) {
	.productphotooverlay {
		width: 1050px;
	}
	.productphotooverlay__gallery {
		width: 65%;	
		max-width: 700px;
	}
}