.pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 8px 20px 8px 0px;
	border-top: 1px #EAEEF3 solid;
	background-color: #fff;
    border-radius: 0 0 4px 4px;
}

.pagination__back {
    padding-right: 5px;
}
