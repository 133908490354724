/*-------------------------
Basic Overlay Styles
---------------------------*/
.overlay {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: rgba(0, 9, 29, 0.75);
}

.overlay__content {
	margin-top: 90px;
	padding-bottom: 30px;
	position: relative;
}

.overlay__content-inner {
	border-radius: 4px;
	overflow: hidden;
}

.overlay__content .icon--close {
	top: 9px;
	right: 13px;
	position: absolute;
	font-size: 1.125rem;
	transition: 0.2s;
	line-height: 1;
}

.overlay__content .icon--close:hover {
	color: #217ad9;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}

/*-------------------------
Overlay Style 1
---------------------------*/
.overlay-s1 {
	width: 100vw;
	max-width: 90vw;
	background-color: #fff;
}

.overlay-s1__heading {
	padding: 25px 30px;
}

.overlay-s1__body {
	background-color: #f5f7f9;
	width: 100%;
	padding: 25px 30px 35px;
	min-height: 215px;
}

.overlay-s1__body--message {
	padding-top: 35px;
	padding-bottom: 35px;
}

.overlay-s1__btns {
	padding: 30px;
}

.overlay-s1__btns > .btn {
	display: block;
	margin: 10px 0 0;
	width: 100%;
}
.overlay-s1__btns > .btn:nth-of-type(1) {
	margin-top: 0;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.overlay-s1 {
		width: 600px;
	}
	.overlay-s1__btns {
		display: flex;
		justify-content: flex-end;
	}
	.overlay-s1__btns > .btn {
		width: auto;
		margin: 0 0 0 18px;
	}
	.overlay-s1__btns > .btn:nth-of-type(1) {
		margin-left: 0;
	}
	.overlay-s1__btns > .btn:not(:nth-last-of-type(1)):nth-of-type(1) {
		margin-right: auto;
	}
}
@media only screen and (min-width: 992px) {
	.overlay-s1--larger {
		width: 900px;
	}
}
@media only screen and (min-width: 1200px) {
}

/*-------------------------
Overlay Style 2
---------------------------*/
.overlay-s2 {
	width: 100vw;
	max-width: 90vw;
	background-color: #fff;
}

.overlay-s2__heading {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 25px 30px;
}

.overlay-s2__btns {
	padding: 30px;
}

.overlay-s2__btns > .btn {
	display: block;
	margin: 10px 0 0;
	width: 100%;
}

.overlay-s2__btns > .btn:nth-of-type(1) {
	margin-top: 0;
}

.overlay-s2__body {
	border-top: 1px solid #eaeef3;
	border-bottom: 1px solid #eaeef3;
}

.overlay-s2__body-col {
	padding: 36px;
	background-color: #f4f7fb;
}

.overlay-s2__body-col--content {
	padding: 40px;
	background-color: white;
}

.overlay-s2__navigation-item {
	text-decoration: none;
	color: #221f20;
	transition: 0.2s;
	padding: 5px 10px;
	margin-bottom: 5px;
	display: block;
	width: 100%;
	text-align: left;
}

.overlay-s2__navigation-item:hover {
	color: #908f8f;
}

.overlay-s2__navigation-item--active {
	background-color: #217ad9;
	border-radius: 4px;
	color: white;
}

.overlay-s2__navigation-item--active:hover {
	color: white;
}

.overlay-s2__body-title {
	margin-bottom: 20px;
	font-size: 1.125rem;
}

.overlay-s2__body-note {
	line-height: 1.1875;
	margin-top: 25px;
}

.overlay-s2__field-entry:not(:last-of-type) {
	margin-bottom: 25px;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.overlay-s2 {
		width: 600px;
	}
	.overlay-s2__btns {
		display: flex;
		justify-content: flex-end;
	}
	.overlay-s2__btns > .btn {
		width: auto;
		margin: 0 0 0 18px;
	}
	.overlay-s2__btns > .btn:nth-of-type(1) {
		margin-left: 0;
	}
	.overlay-s2__btns > .btn:not(:nth-last-of-type(1)):nth-of-type(1) {
		margin-right: auto;
	}
}
@media only screen and (min-width: 992px) {
	.overlay-s2 {
		width: 900px;
	}

	.overlay-s2__body {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
	}

	.overlay-s2__body-col--content {
		flex: 1;
		width: auto;
	}

	.overlay-s2__body-col {
		width: 260px;
	}

	.overlay-s2__body-col--content > :nth-last-child(1) {
		margin-bottom: 40px;
	}
}
@media only screen and (min-width: 1200px) {
}
