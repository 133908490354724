.layout0 {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.layout0__navbar {
	background-color: #062465;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
	min-height: 75px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.layout0__main {
	flex: 1;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
}

.layout0__content {
    width: 100%;
    padding: 0px 20px 0px 20px;
}


@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
    .layout0__content {
        width: 450px;
    }
}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}