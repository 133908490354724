.forgot-password__header {
    margin-bottom: 25px;
}

.forgot-password__header .heading--6 {
    margin-bottom: 3px;
}

.forgot-password__instructions {
	margin: 20px 0 0;
	color: #646262;
}

.forgot-password .input--1:nth-of-type(2) {
    margin-top: 10px;
}

.forgot-password .btn--primary {
	margin-top: 35px;
}

.forgot-password .btn--secondary {
	margin: 10px 0 0;
}