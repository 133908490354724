
.system-notification {
	position: fixed;
	bottom: 0;
	right: 20px;
	width: calc(100vw - 40px);
	transform: translateY(100%);
	border-radius: 4px;
	background-color: #24A107;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	color: #fff;
	padding: 12px 12px 12px 20px;
	overflow: hidden;
	z-index: 5000;
	opacity: 0;
	transition: all .35s;
}
.system-notification--running {
	bottom: 20px;
	transform: translateY(0);
	opacity: 1;
}

.system-notification:before {
	font-size: 1rem;
}

.system-notification:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 4px;
	width: 0;
	background-color: #1A7C03;
	transition: width 2.5s linear;
}

.system-notification--running:after {
	width: 100%;
}

.system-notification__text {
	font-size: 1rem;
	flex: 1;
	margin: 0 20px 0 10px;
}

.system-notification__btn {
	background-color: #1A7C03;
	padding: 10px 15px;
	border-radius: 4px;
	line-height: normal;
	text-transform: uppercase;
	font-size: .75rem;
	font-weight: bold;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.system-notification {
		width: auto;
	}
	.system-notification__text {
		margin-right: 45px;
	}

}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}