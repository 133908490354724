.presentationsidebar {
	padding: 25px 20px;
}

.presentationsidebar__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.presentationsidebar__toggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.presentationsidebar__toggle .iconbtn {
	margin: 0 0 0 5px;
}

.presentationsidebar__products {
	margin: 20px 0 0;
}

.presentationsidebar__product {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #F5F7F9;
	margin: 12px 0 0;
	padding: 0 0 15px;
}

.presentationsidebar__product:nth-last-of-type(1) {
	padding: 0;
	border-bottom: none;
}

.presentationsidebar__product > :nth-child(1) {
	flex: 1;
	margin-right: 20px;
}

.presentationsidebar__list-heading {
	margin: 45px 0 0;
}
.presentationsidebar__list-heading:nth-of-type(1) {
	margin-top: 0;
}

.presentationsidebar__product-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 12px 0 0;
}

.presentationsidebar__list-heading + .presentationsidebar__product-list {
	margin-top: 6px;
}

.presentationsidebar__product-list > :nth-child(1) {
	flex: 1;
	margin-right: 20px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}


/*
.userwelcome {
	
}

.userwelcome__account {
	border-bottom: 1px #EAEEF3 solid;
	padding: 30px 20px 30px 20px;
}

.userwelcome .heading--6 {
	margin-bottom: 2px;
}

.userwelcome__link {
	margin: 10px 0 0;
}

.userwelcome__actions {
	padding: 30px 20px;
}

.userwelcome__action {
	color: #217AD9;
}
.userwelcome__action:before {
	margin: 0 8px 0 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}
*/