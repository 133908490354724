.iconbtn {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-width: 30px;
	min-height: 30px;
	padding: 0 8px;
	text-decoration: none;
    font-size: 0.875rem;
    border-radius: 4px;
	transition: .2s;
	white-space: nowrap;
}

.iconbtn:hover {
    opacity: .8;
}

.iconbtn--primary {
    color: #217AD9;
    background-color: #F3F7FF;
}

.iconbtn--no-bg {
    color: #217AD9;
    background-color: #fff;
}

.iconbtn--secondary {
    color: #A2A2A2;
    background-color: #F0F0F0;
}

.iconbtn--danger {
    color: #DC143C;
    background-color: #F0F0F0
}

.iconbtn--solid {
    background-color: #217AD9;
    color: white;
}

.iconbtn--inactive {
    background-color: #F4F7FB;
    color: #D2D8DF;
    cursor: not-allowed;
}

.iconbtn--minus {

}

.iconbtn--light {
    color: #D3D2D2;
}

.iconbtn > span {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	line-height: normal;
}

.iconbtn__text:not(:empty) {
    text-transform: uppercase;
    font-size: 0.75rem;
	font-weight: 700;
	padding: 0 0 0 7px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}