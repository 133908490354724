.submenu {
	z-index: 1;
	position: relative;
    display: inline-block;
}

.submenu--open {
	z-index: 2;
}

.submenu__wrapper {
    display: none;
}

.submenu--open .submenu__wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    background: white;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    margin-top: 5px;
    /* display: inline-block; */
    /* margin-top: 5px;  */

}

.submenu .iconbtn {
    background-color: #FFFFFF;
}

.submenu--open .iconbtn {
    background-color: #F3F7FF;
}

.submenu--open.submenu--alt .submenu__wrapper {
    left: 0;
    right: auto;
}

.submenu__primary-actions {
    border-bottom: 1px #F3F7FF solid;
    padding: 10px 0px;
}

.submenu__secondary-actions {
    padding: 10px 0px;
}

.submenu__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 40px 10px 20px;
    width: 100%;
	text-align: left;
	white-space: nowrap;
}


.submenu__item-icon{
    color: #217AD9;
    background-color: #F3F7FF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.submenu__secondary-actions .submenu__item-icon {
    color: #A2A2A2;
    background-color: #F0F0F0;
    
}

.submenu_item-text {
    margin-left: 8px;
}

/* Responsive Design */
@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}