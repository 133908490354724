.additionaldiscountoverlay {
	width: 100%;
	background-color: #F4F7FB;
	padding: 25px 30px 35px;
	min-height: 215px;
}

.additionaldiscountoverlay__description {
	margin: 10px 0 0;
}

.additionaldiscountoverlay__preview {
	height: 115px;
	border: 1px solid #EAEEF3;
	background: #FFFFFF;
	border-radius: 4px;
	margin: 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.additionaldiscountoverlay__type {
	display: block;
	width: 100%;
}

.additionaldiscountoverlay__type .input__toggle-switch {
	background-color: #fff;
	padding: 0 6px;
	justify-content: space-between;
	width: 100%;
}

.additionaldiscountoverlay__type .input__toggle-switch:after {
	background-color: #217AD9;
	left: 6px;
	width: calc(50% - 6px);
}

.additionaldiscountoverlay__type .input__toggle-checkbox:checked + .input__toggle-switch::after {
	left: 50%;
}

.additionaldiscountoverlay__type .input__toggle-switch-label {
	color: #fff !important;
	text-align: center;
	flex: 1;
	width: calc((100% - 10px) / 2);
}

.additionaldiscountoverlay__type .input__toggle-switch-label:nth-of-type(2) {
	color: #8D8D8D !important;
}

.additionaldiscountoverlay__type .input__toggle-checkbox:checked + .input__toggle-switch .input__toggle-switch-label {
	color: #8D8D8D !important;
}

.additionaldiscountoverlay__type .input__toggle-checkbox:checked + .input__toggle-switch .input__toggle-switch-label:nth-of-type(2) {
	color: #fff !important;
}


@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.additionaldiscountoverlay {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-start;
	}
	.additionaldiscountoverlay__info {
		margin: 0 30px 0 0;
		width: 50%;
	}
	.additionaldiscountoverlay__number {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.additionaldiscountoverlay__preview {
		flex: 1;
		margin-top: 0;
	}
}
@media only screen and (min-width: 992px) {
	.additionaldiscountoverlay {
		justify-content: center;
	}
	.additionaldiscountoverlay__info {
		width: 400px;
		margin-right: 50px;
	}
	.additionaldiscountoverlay__number {
		width: 250px;
		flex: none;
	}
}
@media only screen and (min-width: 1200px) {}