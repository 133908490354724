.erroroverlay {

}

.erroroverlay__error {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
	margin: 10px 0 0;
}

.erroroverlay__error:before {
	line-height: 1.3;
    margin-right: 10px;
    color: #CE2A2C;
}

.erroroverlay__error .heading {
	flex: 1;
	line-height: normal;
    font-weight: 700;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}