.navbarlogo {
	display: block;
	width: 181px;
	height: auto;
	margin: 20px 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}