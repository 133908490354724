.layout2 {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.layout2__navbar {
	background-color: #062465;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
	min-height: 75px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.layout2__main {
	flex: 1;
	display: flex;
	flex-direction: row;
}

.layout2__content {
    padding: 20px;
    background-color: #F4F7FB;
    width: 100%;
}

.layout2__content--nopadding {
	padding : 0;
}

.layout2__content--altbkg {
	background-color: #fff;
}

.layout2__content--flex-column {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}