.productconfigurationnavbar {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;
}

.productconfigurationnavbar__action-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.productconfigurationnavbar__actions {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.productconfigurationnavbar__actions .actionbtn:nth-of-type(1) {
	margin-right: 10px;
}

@media only screen and (min-width: 480px) {
	.productconfigurationnavbar {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;;
	}
	.productconfigurationnavbar__action-group {
		margin: 0;
	}
	.productconfigurationnavbar__actions {
		margin: 0 0 0 50px;
	}
}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}