.productconfiguration {
}

.productconfiguration__heading {
	margin-bottom: 10px;
}

.productconfiguration__disclaimer {
	color: #646262;
	font-size: 0.9375rem;
	margin: 30px 0 0;
}
.productconfiguration__disclaimer:before {
	content: '*';
}

/* Window Location */
.productconfiguration__custom-location {
	margin: 10px 0 0;
}

/* Window Size */
.productconfiguration__windowsize-inputs {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}

.input + .productconfiguration__windowsize-inputs {
	margin: 10px 0 0;
}

.productconfiguration__windowsize-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.productconfiguration__windowsize-input .input {
	width: 200px !important;
}

.productconfiguration__windowsize-times {
	display: none;
}

.productconfiguration__windowsize-input
	+ .productconfiguration__windowsize-input
	.productconfiguration__windowsize-times {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 25px 20px 0;
}

.productconfiguration__windowsize-output {
	display: flex;
	flex-direction: row;
	/* margin-top: 7px; */
	align-items: center;
	max-height: 60px;
}
.productconfiguration__windowsize-output-icon {
	background-color: white;
	border: #eaeef3 1px solid;
	border-width: 1px 1px 1px 1px;
	color: #217ad9;
	border-radius: 0px 0px 0px 4px;
	max-height: 50px;
	padding: 15px 12px;
}

.productconfiguration__windowsize-output-calculation {
	background-color: white;
	height: 100%;
	border: #eaeef3 1px solid;
	border-width: 1px 1px 1px 0px;
	height: 50px;
	border-radius: 0px 0px 4px 0px;
	padding: 14px 0 0 12px;
	width: 100%;
}

/* Section Styles */
.productconfiguration__section {
	background-color: white;
	border-radius: 4px;
}

.productconfiguration__section .productconfiguration__section-body,
.productconfiguration__section .iconbtn--secondary,
.productconfiguration__section--open .iconbtn--primary,
.productconfiguration__subsection .productconfiguration__subsection-body,
.productconfiguration__subsection .icon--dropdown-open,
.productconfiguration__subsection--open .icon--dropdown {
	display: none;
}

.productconfiguration__section--open .productconfiguration__section-body,
.productconfiguration__subsection--open .productconfiguration__subsection-body {
	display: block;
}

.productconfiguration__subsection--open .icon--dropdown-open,
.productconfiguration__section--open .iconbtn--secondary {
	display: inline-flex;
}

.productconfiguration__section--open {
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	border-top: 4px solid #217ad9;
}

.productconfiguration__section--open .productconfiguration__section-header {
	border-bottom: 1px solid #ebebeb;
}

.productconfiguration__section-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
}

.productconfiguration__header-number {
	padding: 25px 15px;
	background-color: #f4f7fb;
	border: 3px solid white;
	border-radius: 4px 0px 0px 4px;
}

.productconfiguration__header-info {
	margin-left: 12px;
	margin-right: 90px;
	flex-grow: 1;
	text-align: left;
}

.productconfiguration__header-info .heading--3 {
	margin-bottom: 4px;
	margin-top: -4px;
}

.productconfiguration__section-toggle {
	margin-right: 12px;
}

.productconfiguration__section-body {
	padding: 25px 20px 35px 20px;
}

.productconfiguration__section-thumbnail {
	height: 58px;
	width: 58px;
	border-radius: 4px;
	border: 1px solid #ebebeb;
	margin-right: 25px;
	overflow: hidden;
	position: relative;
}

.productconfiguration__section-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 4px;
}

.productconfiguration__section-thumbnail--colors {
	position: relative;
	margin-right: 25px;
}

.productconfiguration__thumbnail-color {
	position: absolute;
	width: 30px;
	height: 30px;
	bottom: 0;
	right: 0px;
	z-index: 10;
	transform: translateY(50%);
	border-radius: 50%;
	border: 1px solid white;
	box-shadow: 0px 0px 0px 2px rgba(34, 31, 32, 0.1);
	overflow: hidden;
}

.productconfiguration__thumbnail-color:nth-of-type(2) {
	right: 20px;
	z-index: 11;
}
.productconfiguration__thumbnail-color:nth-of-type(3) {
	right: 40px;
	z-index: 12;
}

.productconfiguration__thumbnail-color img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.productconfiguration__color-secondary {
	margin-top: 30px;
}

.productconfiguration__color-secondary .heading--6 {
	margin-bottom: 10px;
}

.productconfiguration__calculationwrapper {
	margin-bottom: 25px;
}

.productconfiguration__calculationwrapper:nth-last-of-type(1) {
	margin-bottom: 0;
}

.productconfiguration__subsection {
	margin: 0 10px 30px 0;
}

.productconfiguration__subsection-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin: 0 0 0 0;
}

.productconfiguration__subsection-header .heading {
	white-space: nowrap;
}

.productconfiguration__subsection-header .config-divider {
	flex: 1;
	margin: 0 20px 0 25px;
}

.productconfiguration__secondarydropdown {
	margin: 15px 0;
	background: #f4f7fb;
	border: 1px solid #f3f5f6;
	border-radius: 0 0 4px 4px;
	width: 100%;
	padding: 20px 20px;
}

.productconfiguration__secondarydropdown .heading--5 {
	margin-top: 12px;
}

.productconfiguration__secondarydropdown .input {
	width: auto !important;
	display: inline-block !important;
}

.productconfigurationmiscproduct {
	display: flex;
	flex-direction: column;
}

.productconfigurationmiscproduct__topfields {
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
}

.productconfigurationmiscproduct__field {
	height: 100%;
}

.productconfigurationmiscproduct__field:first-of-type {
	flex: 2;
	margin-right: 0px;
	margin-bottom: 25px;
}

.productconfigurationmiscproduct__field:last-of-type {
	flex: 1;
}

.productconfigurationmiscproduct .heading--5 {
	display: block;
	margin-bottom: 10px;
}
.productconfigurationmiscproduct__textarea {
	background: #f4f7fb;
	border: 1px solid #eaeef3;
	border-radius: 4px;
	padding: 15px 14px;
	margin-top: 6px;
	width: 100%;
	overflow: auto;
	min-height: 210px;
}

.productconfiguration__grid-location-details {
}

.productconfiguration__grid-location {
}

.productconfiguration__grid-details {
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.productconfiguration__secondarydropdown {
		padding: 35px 30px;
	}
	.productconfiguration__calculations {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
	.productconfiguration__calculationwrapper {
		margin-right: 20px;
	}
	.productconfiguration__calculationwrapper:nth-of-type(2n) {
		min-width: 40%;
		margin-right: 0;
	}
	.productconfiguration__calculationwrapper:nth-last-of-type(1),
	.productconfiguration__calculationwrapper:nth-last-of-type(2) {
		margin-bottom: 0;
	}
	.productconfigurationmiscproduct__topfields {
		flex-direction: row;
	}
	.productconfigurationmiscproduct__field:first-of-type {
		margin-right: 25px;
		margin-bottom: 0px;
	}
}
@media only screen and (min-width: 992px) {
	.productconfiguration__calculationwrapper {
		margin-right: 25px;
	}
	.productconfiguration__calculationwrapper:nth-of-type(2n) {
		min-width: 60%;
		margin-right: 0;
	}
	.layout1--productconfiguration
		.layout1__sidebar
		.productconfiguration__windowsize-input
		.input {
		width: 105px !important;
	}
	.productconfiguration__grid-location-details > .config-divider {
		display: none;
	}
	.productconfiguration__grid-location-details {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-self: flex-start;
	}
	.productconfiguration__grid-location {
		width: 260px;
		border-right: 1px solid #ebebeb;
		margin: 0 46px 0 0;
	}
	.productconfiguration__grid-details {
		flex: 1;
	}
}
@media only screen and (min-width: 1200px) {
	.productconfiguration__calculationwrapper {
		min-width: auto !important;
		margin-right: 25px !important;
		margin-bottom: 0;
	}
	.productconfiguration__calculationwrapper:nth-of-type(4) {
		margin-top: 25px !important;
	}
}
@media only screen and (min-width: 1300px) {
	.productconfiguration__calculationwrapper:nth-of-type(4) {
		margin-top: 0 !important;
	}
}
