.quotenavbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
	margin-bottom: 20px;
	min-height: 75px;
}

.quotenavbar__actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.quotenavbar__actions .actionbtn {
	margin: 0 0 0 10px;
}

.quotenavbar__actions .actionbtn:nth-of-type(1) {
	margin-left: 0;
}

@media only screen and (min-width: 480px) {
    .quotenavbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
    }

}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}
