.actionbtn {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
    font-size: 1.125rem;
    border-radius: 4px;
	transition: .2s;
	width: 57px;
	height: 50px;
}

.actionbtn:hover {
    opacity: .8;
}

.actionbtn--primary {
    background-color: #217AD9;
    border: 1px #217AD9 solid;
    color: white;
}

.actionbtn--secondary {
    background-color: #F3F7FF;
    border: 1px #F3F7FF solid;
    color: #217AD9;
}

.actionbtn--outline {
    color: white;
    border: 1px white solid;
}

.actionbtn--primary-outline {
    color: #217AD9;
    border: 1px #217AD9 solid;
}

.actionbtn--negative-outline {
    color: #CE2A2C;
    border: 1px #CE2A2C solid;
}

.actionbtn--light {
    color: white;
    background-color: #445A8B;
}

.actionbtn--active {
    color: #217AD9;
    background-color: white;
}