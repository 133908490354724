.fielderror {
    background-color: rgba(206, 42, 45, 0.05);
    padding: 10px 10px 10px 15px;
    border: 1px #CE2A2C solid;
    display: flex;
    flex-direction: row;
	border-radius: 4px;
	margin: 5px 0 0;
}

.fielderror__text {
    color: #CE2A2C;
    margin-bottom: 2px;
}

.fielderror__heading {
    color: #CE2A2C;
    margin: 3px 15px 0px 0px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}
