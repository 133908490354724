.input:not(.input--checkbox):not(.input--toggle):not(.input--select-text) {
	display: block;
	width: 100%;
	color: #575456;
	font-size: 1rem;
	font-weight: 400;
	border: #eaeef3 1px solid;
	border-radius: 4px;
	line-height: 1.75px;
	transition: 0.2s;
	position: relative;
}

.input--1 input,
.input--1 textarea,
.input--1 select {
	padding: 14px 15px 17px;
	min-width: 170px;
}

.input--feet input,
.input--inches input,
.input--1 select {
	padding-right: 40px;
}

.input--sqft input {
	padding-right: 50px;
}

.input--1 textarea,
.input--1 select,
.input--sqft input,
.input--feet input,
.input--price input,
.input--inches input,
.input--percent input {
	width: 100%;
	position: relative;
	z-index: 2;
}

.input--sqft::before,
.input--feet::before,
.input--inches::before,
.input--percent::before {
	font-style: normal;
	font-weight: 700;
	font-size: 0.875rem;
	color: #9ca2b1;
	display: inline-block;
	position: absolute;
	top: 46%;
	right: 15px;
}

.input--price::before {
	content: '$';
	font-style: normal;
	font-weight: 700;
	font-size: 0.875rem;
	color: #9ca2b1;
	display: inline-block;
	position: absolute;
	top: 46%;
	left: 15px;
}

.input--price input {
	padding-left: 30px;
}

.input--sqft::before {
	content: 'sq ft';
}

.input--feet::before {
	content: 'ft';
}

.input--inches::before {
	content: 'in';
}

.input--percent {
	max-width: 110px;
}

.input--percent::before {
	content: '%';
}

.input--percent input {
	padding-right: 30px;
}

.input--required::before {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 700;
	font-size: 0.5rem;
	content: '\f069';
	color: #9ca2b1;
	display: inline-block;
	position: absolute;
	top: 48%;
	right: 15px;
}

.input--select-text {
	display: block;
	position: relative;
	width: 100%;
	font-weight: 400;
	font-size: 1rem;
	line-height: normal;
	color: #221f20;
}

.input--select-text::after {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 0.75rem;
	content: '\f078';
	color: #7a7979;
	position: absolute;
	right: 0;
	bottom: 50%;
	transform: translateY(50%);
	z-index: 1;
}

.input--select-text select {
	position: relative;
	padding-right: 15px;
	z-index: 2;
}

.input--search input {
	padding-left: 35px;
}

.input--search:before {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	content: '\f002';
	color: #217ad9;
	position: absolute;
	left: 12px;
	bottom: 50%;
	transform: translateY(50%);
	z-index: 1;
}

.input--1 {
	background-color: #f4f7fb;
	width: 100%;
}

.input--output {
	background-color: white;
	border-radius: 0px 0px 4px 0px !important;
}

.input--hover:not(.input--select-text) {
	background-color: rgb(235, 240, 245);
}

.input--focus:not(.input--checkbox) {
	background-color: white;
	border: #dde5ee 1px solid;
}

.input--autowidth {
	display: inline-block !important;
	width: auto !important;
	max-width: 100% !important;
}

.input--select::before {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	content: '\f078';
	color: #221f20;
	display: inline-block;
	position: absolute;
	top: 48%;
	right: 15px;
}

input[type='checkbox'] + .input__checkbox span {
	display: inline-block;
	width: 25px;
	height: 25px;
	background-color: white;
	border: 1px #217ad9 solid;
	border-radius: 4px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.875rem;
	transition: 0.1s;
}

input[type='checkbox']:checked + .input__checkbox span {
	background-color: #217ad9;
	border-radius: 4px;
	color: white;
}

input[type='checkbox'] {
	height: 0;
	width: 0;
}

.input__checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.input__checkbox-text {
	margin-left: 8px;
	flex: 1;
}

/* Toggle */
.input__toggle-switch {
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 125px;
	height: 50px;
	background-color: #f4f7fb;
	border-radius: 4px;
	transition: all 0.2s;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
}

.input__toggle-switch::after {
	content: '';
	position: absolute;
	width: 50px;
	height: 38px;
	border-radius: 4px;
	background-color: rgb(214, 217, 219);
	top: 6px;
	left: 6px;
	transition: all 0.2s;
}

/* Quantity */

.qty-input {
	display: inline-block;
}

.qty-input__input {
	width: 49px;
	height: 50px;
	padding: 0 5px;
	text-align: center;
	background-color: #f4f7fb;
	border: 1px solid #f4f7fb;
	font-size: 1.125rem;
	-moz-appearance: textfield;
}

.qty-input__plus,
.qty-input__minus {
	width: 38px;
	color: #217ad9;
	font-size: 1.25rem;
}

.qty-input.qty-input__buttons {
	text-align: left;
	position: relative;
	white-space: nowrap;
	vertical-align: top;
}

.qty-input.qty-input__buttons input {
	display: inline-block;
	margin: 0;
	vertical-align: top;
	box-shadow: none;
}

.qty-input.qty-input__buttons .qty-input__minus,
.qty-input.qty-input__buttons .qty-input__plus {
	height: 50px;
	background-color: #e3ecfe;
	border: 1px solid #e3ecfe;
	cursor: pointer;
	transition: 0.2s;
}

.qty-input.qty-input__buttons .qty-input__minus {
	border-right: 0;
	margin-right: -4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.qty-input.qty-input__buttons .qty-input__plus {
	border-left: 0;
	margin-left: -4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.qty-input.qty-input__buttons .qty-input__minus:hover,
.qty-input.qty-input__buttons .qty-input__plus:hover {
	background: #cdddfe;
}

.qty-input input::-webkit-outer-spin-button,
.qty-input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}

.qty-input.qty-input__buttons .qty-input__minus:focus,
.qty-input.qty-input__buttons .qty-input__plus:focus {
	outline: none;
}

.qty-input.qty-input__buttons button:disabled {
	background: #d6d9db;
	color: #000;
}

.qty-input.qty-input__buttons button:hover:disabled {
	background: #d6d9db;
	color: #000;
	cursor: not-allowed;
}

.input__toggle-checkbox:checked + .input__toggle-switch::after {
	left: 68px;
	background-color: #217ad9;
}

.input__toggle-checkbox {
	display: none;
}

.input__toggle-switch-label {
	display: inline-block;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.1875rem;
	color: #221f20;
	z-index: 100;
	transition: all 0.2s;
}

.input--error {
	border: 1px #ce2a2c solid !important;
	box-shadow: 0px 0px 5px 0px rgba(206, 42, 45, 0.3);
}

.input__toggle-checkbox:checked
	+ .input__toggle-switch
	.input__toggle-switch-label:nth-of-type(2) {
	color: white;
}

.input__toggle-checkbox:checked
	+ .input__toggle-switch
	.input__toggle-switch-label:nth-of-type(1),
.input__toggle-checkbox
	+ .input__toggle-switch
	.input__toggle-switch-label:nth-of-type(2) {
	color: #8d8d8d;
}

.input__toggle-checkbox
	+ .input__toggle-switch
	.input__toggle-switch-label:nth-of-type(1) {
	color: #221f20;
}

.input--white {
	background-color: white;
}

/* Responsive Design */
@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1200px) {
}
