.contentbox1 {
    border-top: 4px #217AD9 solid;
    background-color: white;
    border-radius: 4px;
    width: 100%;
}

.contentbox1__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
}

.contentbox1__title {
    flex: 1;
    margin: -4px 15px 0px 0px;
}

.contentbox1__empty {
    padding-top: 25px;
    color: #908f8f;
}

.contentbox1__content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}
