.layout1 {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.layout1__navbar {
	background-color: #062465;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 20px;
	min-height: 75px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1000;
}

.layout1__sidebar {
	background-color: white;
}

.layout1__main {
	flex: 1;
}

.layout1__content {
	padding: 20px;
	background-color: #F4F7FB;
}

.layout1__sidebar > .productconfiguration { 
	padding: 10px 20px;
}
.layout1__sidebar > .productconfiguration:nth-last-of-type(1) {
	padding-bottom: 20px;
}
.layout1__sidebar > .productconfigurationsidebar + .productconfiguration {
	padding-top: 20px;
}

.layout1__content > .productconfiguration {
	margin: 10px 0 0;
}

.layout1__content > .productconfiguration:nth-of-type(1) {
	margin-top: 0;
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {
	.layout1__main {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.layout1__sidebar {
		width: 250px;
	}

	.layout1__content {
		flex: 1;
	}
}
@media only screen and (min-width: 992px) {
	.layout1__sidebar {
		width: 300px;
	}
}	
@media only screen and (min-width: 1200px) {}