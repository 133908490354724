.productselectionlist {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.productselectionlist__item {
	transition: .2s;
    background-color: #fff;
    border-radius: 4px;
	margin-bottom: 15px;
	display: block;
	width: 100%;
	overflow: hidden;
}

.productselectionlist__item:nth-last-of-type(1) { 
	margin-bottom: 0;
}

.productselectionlist__item:hover {
    box-shadow: 0px 6px 18px 1px rgb(221, 221, 221);
    z-index: 100;
}

.productselectionlist__item-image {
	position: relative;
	overflow: hidden;
	height: 0;
	padding-top: 90.25%;
}

.productselectionlist__item-image-file {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center center;
	padding: 15px;
}

.productselectionlist__item-name {
	background-color: #217AD9;
	color: #fff;
	font-size: 1.125rem;
	padding: 16px 0;
	font-weight: 700;
}

@media only screen and (min-width: 480px) {
	.productselectionlist__item { 
		width: calc((99.99% - 18px) / 2);
		margin-left: 18px;
	}
	.productselectionlist__item:nth-of-type(2n + 1) {
		margin-left: 0; 
	}
	.productselectionlist__item:nth-last-of-type(2) {
		margin-bottom: 0;
	}
}
@media only screen and (min-width: 768px) {
	.productselectionlist__item,
	.productselectionlist__item:nth-of-type(2n+1) { 
		width: calc((99.99% - (18px * 2)) / 3);
		margin-left: 18px;
	}
	.productselectionlist__item:nth-of-type(3n+1) {
		margin-left: 0;
	} 
}
@media only screen and (min-width: 992px) {
	.productselectionlist__item,
	.productselectionlist__item:nth-of-type(2n+1),
	.productselectionlist__item:nth-of-type(3n+1) { 
		width: calc((99.99% - (18px * 3)) / 4);
		margin-left: 18px;
	}
	.productselectionlist__item:nth-of-type(4n+1) {
		margin-left: 0;
	}
}
@media only screen and (min-width: 1200px) {}