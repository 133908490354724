.productselectionnavbar {
	position: relative;
}

.productselectionnavbar__back {
	position: absolute;
	top: 50%;
	left: 0; 
	transform: translateY(-50%);
}

.productselectionnavbar__text {
	text-align: center; 
}

@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}